import { UICheckbox } from '@/components/ui/Checkbox';
import React, { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import {
  StyledRowWrapper,
  StyledGridRow,
  StyledGridRowContent,
  StyledSpan,
  StyledUserId,
} from './style';
import { ProfilingUsersRowProps, TUserSelect } from './types';
import { User } from '@/api/users';

const RecapUsersRow: React.FC<User & ProfilingUsersRowProps> = ({
  userId,
  name,
  surname,
  exportUserSelected,
  checked,
}) => {
  const [userSelected, setUserSelected] = useState<TUserSelect | undefined>();

  useUpdateEffect(() => {
    if (userSelected) {
      exportUserSelected?.(userSelected);
    }
  }, [userSelected, userSelected?.checked]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUserSelected({ user: userId!, checked: event.target.checked });
  };

  return (
    <StyledRowWrapper>
      <StyledGridRow key={userId}>
        <StyledGridRowContent>
          <StyledUserId>
            <UICheckbox
              color="primary"
              onChange={(event): void => changeHandler(event)}
              checked={checked}
            />
          </StyledUserId>
          <StyledUserId>
            <StyledSpan font="medium">{userId}</StyledSpan>
          </StyledUserId>
          <StyledSpan font="medium">
            {name} {surname}
          </StyledSpan>
        </StyledGridRowContent>
      </StyledGridRow>
    </StyledRowWrapper>
  );
};

export default RecapUsersRow;
