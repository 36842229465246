/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnagRole } from '../models/AnagRole';
import type { CheckRemovableRoles } from '../models/CheckRemovableRoles';
import type { CheckRemovableRolesResponse } from '../models/CheckRemovableRolesResponse';
import type { CreateUser } from '../models/CreateUser';
import type { CreateUserRequestEncoded } from '../models/CreateUserRequestEncoded';
import type { MassiveUserUploadRequest } from '../models/MassiveUserUploadRequest';
import type { PermissionsByStoreCode } from '../models/PermissionsByStoreCode';
import type { PermissionsFullList } from '../models/PermissionsFullList';
import type { PersonalData } from '../models/PersonalData';
import type { PersonalDataDetails } from '../models/PersonalDataDetails';
import type { RoleList } from '../models/RoleList';
import type { SavePersonalData } from '../models/SavePersonalData';
import type { SearchStoreFilter } from '../models/SearchStoreFilter';
import type { StoresAndRoles } from '../models/StoresAndRoles';
import type { UpdateAnagRole } from '../models/UpdateAnagRole';
import type { UserList } from '../models/UserList';
import { request as __request } from '../core/request';

export class ProfilingService {
  /**
   * massive Load Users
   * massive Users Upload
   * @returns any Users load procedure completed successfully
   * @throws ApiError
   */
  public static async userMassiveUsersUpload({
    requestBody,
  }: {
    requestBody: MassiveUserUploadRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/user/massiveUsersUpload`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Internal server errror.`,
      },
    });
    return result.body;
  }

  /**
   * massive Load Users
   * massive Load Users
   * @returns any Users load procedure completed successfully
   * @throws ApiError
   */
  public static async userMassiveLoadUsers(): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/user/massiveLoadUsers`,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Internal server errror.`,
      },
    });
    return result.body;
  }

  /**
   * @deprecated
   * Load all user
   * find user
   * @returns UserList OK
   * @throws ApiError
   */
  public static async userFindAllUsers({
    storeCode,
  }: {
    /** use this field only when calling this service for store details from the control panel **/
    storeCode?: any;
  }): Promise<UserList> {
    const result = await __request({
      method: 'GET',
      path: `/user/findAllUsers`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * form user personal data details
   * user personal dta details
   * @returns PersonalDataDetails OK
   * @throws ApiError
   */
  public static async userUserPersonalDataDetails(): Promise<PersonalDataDetails> {
    const result = await __request({
      method: 'GET',
      path: `/user/userPersonalDataDetails`,
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * search store by sales and store
   * search store by sales and store
   * @returns StoresAndRoles OK
   * @throws ApiError
   */
  public static async userSearchStoreForSalesAndStoreCode({
    requestBody,
  }: {
    requestBody: SearchStoreFilter;
  }): Promise<StoresAndRoles> {
    const result = await __request({
      method: 'POST',
      path: `/user/searchStoreForSalesAndStoreCode`,
      body: requestBody,
      errors: {
        400: `bad input parameter, storeCode is mandatory`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Internal server errror.`,
      },
    });
    return result.body;
  }

  /**
   * create a new user
   * create a new user
   * @returns any User created.
   * @throws ApiError
   */
  public static async userCreateUser({
    requestBody,
  }: {
    requestBody: CreateUser;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/user/createUser`,
      body: requestBody,
      errors: {
        400: `Bad request. userId is mandatory.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Internal server errror.`,
      },
    });
    return result.body;
  }

  /**
   * create a new user
   * create a new user
   * @returns any User created.
   * @throws ApiError
   */
  public static async userCreateUserEncoded({
    requestBody,
  }: {
    requestBody: CreateUserRequestEncoded;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/user/createUserEncoded`,
      body: requestBody,
      errors: {
        400: `Bad request. userId is mandatory.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Internal server errror.`,
      },
    });
    return result.body;
  }

  /**
   * user personal data for edit
   * user personal data details for edit
   * @returns PersonalData OK
   * @throws ApiError
   */
  public static async userGetPersonalData({
    userId,
  }: {
    /** filter for userId **/
    userId: any;
  }): Promise<PersonalData> {
    const result = await __request({
      method: 'GET',
      path: `/user/getPersonalData`,
      query: {
        userId: userId,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * user store and roles details
   * user store and roles details
   * @returns StoresAndRoles OK
   * @throws ApiError
   */
  public static async userGetStoreAndRoles({
    userId,
  }: {
    /** filter for userId **/
    userId: any;
  }): Promise<StoresAndRoles> {
    const result = await __request({
      method: 'GET',
      path: `/user/getStoreAndRoles`,
      query: {
        userId: userId,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * save user personal data details
   * save user personal data details
   * @returns any Saved user personal data details.
   * @throws ApiError
   */
  public static async userSavePersonalDataDetails({
    requestBody,
  }: {
    requestBody: SavePersonalData;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/user/savePersonalData`,
      body: requestBody,
      errors: {
        400: `Bad request. userId is mandatory.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Internal server errror.`,
      },
    });
    return result.body;
  }

  /**
   * returns the list of roles and permissions for that logged in store and user
   * returns the list of roles and permissions for that logged in store and user
   * @returns PermissionsByStoreCode OK
   * @throws ApiError
   */
  public static async userGetRolesByStoreCode({
    storeCode,
  }: {
    /** filter for storeCode and user by jwt token **/
    storeCode: any;
  }): Promise<PermissionsByStoreCode> {
    const result = await __request({
      method: 'GET',
      path: `/user/getPermissionsByStoreCode`,
      query: {
        storeCode: storeCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Load all Roles
   * find all roles on database
   * @returns RoleList OK
   * @throws ApiError
   */
  public static async userFindAllRoles(): Promise<RoleList> {
    const result = await __request({
      method: 'GET',
      path: `/user/findAllRoles`,
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * Load all Permissions
   * Load all Permissions, if a Role name is passed returns also a true flag in each permission active for that role, if Role name is not passed that flag will always be false
   * @returns PermissionsFullList OK
   * @throws ApiError
   */
  public static async userFindAllPermissions({
    roleName,
  }: {
    /** if a Role name is passed returns also a true flag in each permission active for that role, if Role name is not passed that flag will always be false **/
    roleName?: any;
  }): Promise<PermissionsFullList> {
    const result = await __request({
      method: 'GET',
      path: `/user/findAllPermissions`,
      query: {
        roleName: roleName,
      },
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * create a new role
   * create a new role
   * @returns any Role created.
   * @throws ApiError
   */
  public static async userCreateRole({
    requestBody,
  }: {
    requestBody: AnagRole;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/user/createRole`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Internal server errror.`,
      },
    });
    return result.body;
  }

  /**
   * updates a role
   * update a new role
   * @returns any Role updated.
   * @throws ApiError
   */
  public static async userUpdateRole({
    requestBody,
  }: {
    requestBody: UpdateAnagRole;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/user/updateRole`,
      body: requestBody,
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        500: `Internal server errror.`,
      },
    });
    return result.body;
  }

  /**
   * delete a role
   * Physical Delete of a user role.
   * @returns any OK
   * @throws ApiError
   */
  public static async userDeleteRole({
    roleDescription,
  }: {
    /** Role description string **/
    roleDescription: any;
  }): Promise<any> {
    const result = await __request({
      method: 'DELETE',
      path: `/user/deleteRole`,
      query: {
        roleDescription: roleDescription,
      },
      errors: {
        400: `Bad request.`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }

  /**
   * verifies if roles can be removed
   * verifies if roles can be removed for a specific user
   * @returns CheckRemovableRolesResponse OK
   * @throws ApiError
   */
  public static async userCheckRemovableRoles({
    requestBody,
  }: {
    requestBody: CheckRemovableRoles;
  }): Promise<CheckRemovableRolesResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/user/checkRemovableRoles`,
      body: requestBody,
      errors: {
        400: `Bad Request`,
        401: `Authorization information is missing or invalid.`,
        403: `Forbidden.`,
        404: `Not Found.`,
        500: `Unexpected error.`,
      },
    });
    return result.body;
  }
}
