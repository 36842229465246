import { FC, useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useAsync } from 'react-use';
import { useAppDispatch } from '@/app/store';
import { useForm, SubmitHandler } from 'react-hook-form';

import { UISelect } from '@/components/ui/Select';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { Tabs } from '@/components/layout/Tabs';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { Form } from '@/components/layout/Form';
import { Tables } from '@/components/layout/Tables';
import { PageLoader } from '@/components/ui/PageLoader';
import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';
import {
  UIAutoCompleteStores,
  // UIAutoCompleteSales,
} from '@/components/ui/AutoComplete';
import TextFieldController from '@/components/ui/TextField/TextFieldController';
import SelectController from '@/components/ui/Select/SelectController';
import { FormInputs } from '@/types/hookFormInput';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import {
  changeDefaultStore,
  changeUserID,
  changeUserStatus,
  getEditStoresAndRoles,
  getEditUserData,
  getStores,
  getUserDetails,
  initProfilingDefaultStore,
  removeStoresBySale,
  saveEditData,
  saveEditStores,
  setRoles,
  undoDeletedRoles,
  undoInsertedRoles,
  undoInsertedStores,
  undoInsertOrDeleteRoles,
} from '@/features/profiling/usersSlice';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';
import { RoleFilter, StoreFilters } from '@/types/filters';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';
import { StoreWithPrinters } from '@/types/store';
import { SelectStore, StatusUser } from '@/types/profiling';
import { ModalAttention } from '@/components/layout/ModalAttention';
import { UISwitch } from '@/components/ui/Switch';
import { UILoader } from '@/components/ui/Loader';
import { ProfilingStoreRolesList } from '@/components/layout/ProfilingStoreRolesList';
import { ChipList } from '@/components/layout/ChipList';
import { ModalRole } from '@/components/layout/ModalRole';
import {
  AlertSnackbar,
  AlertUndoSnackbar,
} from '@/components/ui/AlertSnackbar';
import { AppRoutes } from '@/app/routers';
import { ModalSelectStore } from '@/components/layout/ModalSelectStore';
import { UIButtonWithIcon } from '@/components/ui/Button';
import useRolesOptions from '@/hooks/useRolesOptions';
import useChips, { Chip } from '@/hooks/useChips';
import { SelectedStore } from '@/types/profiling';
import { useRoleModalContext } from '@/context/roleModalContext';
import { ProfilingService } from '@/api/receive';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { makeUrl } from '@/utils/links';
import { UrlUpdateStore } from '@/pages/ControlPanel/PageStoreDetails/types';

//#region - Styled Components
const StyledFilterWrapper = styled(UIBox)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, fit-content(200px)) repeat(3, 1fr);
  gap: 40px;
  padding: 0 24px 24px;
  margin-bottom: 16px;

  & > * {
    margin: 0;
    padding: 0;
    align-self: flex-end;

    & > div {
      width: 100% !important;
    }
  }
`;

const StyledButtonWithIcon = styled(UIButtonWithIcon)`
  width: fit-content;
  align-self: center;
  margin-top: 16px;
`;

const StyledAutocompleteWrapper = styled(UIBox)`
  flex-direction: column;
  padding: 24px;

  & > span {
    margin-bottom: 8px;
  }
`;

const StyledLoaderWrapper = styled(UIBox)`
  margin: 100px 0;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(UIButtonWithIcon)`
  position: absolute;
  right: 40px;
  margin-top: 63px;
`;

const StyledSwitchWrapper = styled(UIBox)`
  width: 100%;
  margin: 12px 32px 0 0;

  & > * {
    margin-left: auto;
  }
`;

const StyledChipList = styled(ChipList)`
  margin-top: 24px;
`;
//#endregion

interface LocationState {
  fromStore: boolean;
  storeToRedirect?: string;
}

const PageControlUsersEdit: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    search,
    state: { fromStore, storeToRedirect },
  } = useLocation<LocationState>();

  const { t, i18n } = useTranslation();
  const {
    chips,
    state: organizations,
    setChips: setOrganizations,
  } = useChips();
  const rolesOptions = useRolesOptions();
  const { roleModal, setRoleModalState } = useRoleModalContext();
  const tabs = [t('personalData'), t('storeAndPrivileges')];
  const query = useMemo(() => qs.parse(search), [search]);

  const {
    details: userDetails,
    editUserDetail: formUserDetails,
    roles,
    getUserDetailsIsloading,
    saveEditDataIsLoading,
    saveEditDataHasError,
    saveEditStoresIsLoading,
    saveEditStoresHasError,
    storesIsLoading,
    stores: { defaultStore, storeBySalesOrganization, salesOrganizations },
  } = useSelector(state => state.profiling.user);

  const [filters, setFilters] = useState<StoreFilters>({
    sales: '',
    storeCode: '',
    role: 'all',
  });

  const [initDefaultStore, setInitDefaultStore] = useState<boolean>(false);
  const [getStoreBySales, setGetStoresBySales] = useState<boolean>(false);
  const [resetValues, setResetValues] = useState<boolean>(true);
  const [selectedStore, setSelectedStore] = useState<StoreWithPrinters>();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [listCurrentPage, setListCurrentPage] = useState<number>(1);
  const [activeUser, setActiveUser] = useState<StatusUser>();
  const [disabledTabs, setDisabledTabs] = useState<number[]>([]);
  const [storeCodesToUndo, setStoreCodesToUndo] = useState<string[]>([]);
  const [saveEnable, setSaveEnable] = useState<boolean>(false);
  const [storesChecked, setAllStoresChecked] = useState<boolean>(false);

  const [attentionModalIsVisible, setAttentionModalVisibility] =
    useState<boolean>(false);

  const [attentionBackModalIsVisible, setAttentionBackModalVisibility] =
    useState<boolean>(false);

  const [alertSaveUserData, setAlertSaveUserDataVisibility] =
    useState<boolean>(false);

  const [alertSaveStores, setAlertSaveStoresVisibility] =
    useState<boolean>(false);

  const [undoStoresSnackbarIsVisible, setUndoStoresSnackbarVisibility] =
    useState<boolean>(false);

  const [insertedRolesSnackbarIsVisible, showInsertedRolesSnackbar] =
    useState<boolean>(false);

  const [deletedRolesSnackbarIsVisible, showDeletedRolesSnackbar] =
    useState<boolean>(false);

  const [undoRolesSnackbarIsVisible, showUndoRolesSnackbar] =
    useState<boolean>(false);

  const [selectStoreModalIsVisible, setSelectStoreModalVisibility] =
    useState<boolean>(false);

  const [rolesToUndo, setRolesToUndo] = useState<SelectedStore[]>([]);

  const [insertedRolesToUndo, setInsertedRolesToUndo] = useState<
    SelectedStore[]
  >([]);

  const [deletedRolesToUndo, setDeletedRolesToUndo] = useState<SelectedStore[]>(
    []
  );

  const [selectedStores, setSelectedStores] = useState<SelectedStore[]>([]);
  const [paginationStores, setPaginationStores] = useState<SelectStore[]>([]);
  const [sale, setSale] = useState<string | null>('');

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isDirty },
    getValues,
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      userId: '',
      jobTitle: '',
      firstName: '',
      lastName: '',
      email: '',
      language: '',
    },
  });

  const setDefaultFormDetails = useCallback((): void => {
    reset({ ...formUserDetails });
  }, [formUserDetails, reset]);

  const setDefaultStoreAndSales = useCallback(() => {
    if (defaultStore?.salesOrg) {
      if (salesOrganizations) {
        if (storeBySalesOrganization) {
          setOrganizations([
            ...new Set(
              storeBySalesOrganization.map(({ salesOrg }) => salesOrg!)
            ),
          ]);
        }
      }
    }

    if (selectedStore?.storeCode !== defaultStore?.storeCode) {
      setSelectedStore({
        storeCode: defaultStore?.storeCode || '',
        storeAddress: defaultStore?.streetAndHouseNumber || '',
        printers: [],
      });
    }

    setResetValues(false);
  }, [
    defaultStore,
    salesOrganizations,
    selectedStore?.storeCode,
    setOrganizations,
    storeBySalesOrganization,
  ]);

  const selectedStoresRoles = [
    ...new Set(selectedStores.flatMap(({ roles }) => roles)),
  ];

  useAsync(async () => {
    if (query) {
      await dispatch(getUserDetails());
      await dispatch(getEditUserData({ userId: query.userId as string }));
      const { roles } = await ProfilingService.userFindAllRoles();
      await dispatch(getEditStoresAndRoles({ userId: query.userId as string }));

      if (roles) {
        dispatch(setRoles(roles));
      }
    }
  }, [dispatch]);

  useAsync(async () => {
    if (getStoreBySales) {
      if (selectedStore?.storeCode) {
        await dispatch(
          getStores({
            initDefaultStore,
            defaultStoreCode: selectedStore?.storeCode,
            salesOrganization: [organizations[organizations.length - 1]],
          })
        );

        setSelectedStores([]);
      }
    }
  }, [getStoreBySales, selectedStore, organizations.length, dispatch]);

  useEffect(() => {
    if (selectedStores.length === paginationStores.length) {
      setAllStoresChecked(true);
    } else {
      setAllStoresChecked(false);
    }
  }, [paginationStores, selectedStores.length]);

  useEffect(() => {
    if (selectedTab === 0) {
      if (isDirty || activeUser !== formUserDetails.statusUser) {
        setSaveEnable(true);
        setDisabledTabs([1]);
      } else {
        setSaveEnable(false);
        setDisabledTabs([]);
      }
    }
  }, [
    activeUser,
    formUserDetails.statusUser,
    isDirty,
    saveEnable,
    selectedTab,
  ]);

  useEffect(() => {
    if (formUserDetails.statusUser) {
      setActiveUser(formUserDetails.statusUser as StatusUser);
    }
  }, [formUserDetails.statusUser]);

  useEffect(() => {
    if (selectedStore) {
      if (
        selectedTab === 1 &&
        selectedStore.storeCode !== defaultStore?.storeCode
      ) {
        setSaveEnable(true);
        setDisabledTabs([0]);
      }
    }
  }, [
    defaultStore?.storeCode,
    isDirty,
    selectedStore,
    selectedStore?.storeCode,
    selectedTab,
  ]);

  useEffect(() => {
    if (selectedStore) {
      if (selectedStore.storeCode !== defaultStore?.storeCode) {
        dispatch(
          changeDefaultStore({
            storeCode: selectedStore.storeCode,
            salesOrg: selectedStore.salesOrganization,
            roles: ['Operator'],
            streetAndHouseNumber: selectedStore.storeAddress,
          })
        );
      }
    }
  }, [defaultStore?.storeCode, dispatch, selectedStore]);

  useEffect(() => {
    if (resetValues) {
      if (selectedTab === 0) {
        setDefaultFormDetails();
      } else {
        setDefaultStoreAndSales();
      }
    }
  }, [
    resetValues,
    selectedTab,
    setDefaultFormDetails,
    setDefaultStoreAndSales,
  ]);

  useEffect(() => {
    if (storeBySalesOrganization && defaultStore?.storeCode) {
      setPaginationStores([defaultStore, ...storeBySalesOrganization]);
    }
  }, [defaultStore, storeBySalesOrganization]);

  useEffect(() => {
    setSale(null);
  }, [organizations.length]);

  useEffect(() => {
    const { sales, storeCode, role } = filters;

    if (sales !== '' || storeCode !== '' || role !== 'all') {
      setPaginationStores(() => {
        if (storeBySalesOrganization && defaultStore) {
          const stores = [defaultStore, ...storeBySalesOrganization];

          if (stores) {
            return stores.filter(store => {
              if (role !== 'all') {
                return (
                  store.storeCode
                    ?.toLowerCase()
                    .includes(storeCode.toLowerCase()) &&
                  store.salesOrg
                    ?.toLowerCase()
                    ?.includes(sales.toLowerCase()) &&
                  store.roles?.includes(role!)
                );
              }

              return (
                store.storeCode
                  ?.toLowerCase()
                  .includes(storeCode.toLowerCase()) &&
                store.salesOrg?.toLowerCase().includes(sales.toLowerCase())
              );
            });
          }

          return [];
        }

        return [];
      });
    }

    if (sales === '' && storeCode === '' && role === 'all') {
      if (storeBySalesOrganization && defaultStore) {
        setPaginationStores([defaultStore, ...storeBySalesOrganization]);
      }
    }
  }, [defaultStore, filters, storeBySalesOrganization]);

  const isEmailFormatValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
    getValues('email')
  );

  const isFormValid =
    defaultStore?.storeCode &&
    getValues('userId') !== '' &&
    getValues('firstName') !== '' &&
    getValues('lastName') !== '' &&
    getValues('language') !== '';
  //  &&
  // getValues('jobTitle') !== '';

  const confirmDisabled =
    !isFormValid || (getValues('email') !== '' && !isEmailFormatValid);

  const redirectTo = (): void => {
    if (fromStore && storeToRedirect) {
      history.push(
        makeUrl<UrlUpdateStore>(AppRoutes.STORE_DETAILS, {
          store: storeToRedirect,
        })
      );
    } else {
      history.push(AppRoutes.MANAGE_USERS);
    }
  };

  const onFormSubmit: SubmitHandler<FormInputs> = async (
    userData
  ): Promise<void> => {
    if (selectedTab === 0) {
      const { userId, jobTitle, language, firstName, lastName, email } =
        userData;
      try {
        await dispatch(
          saveEditData({
            userId: userId.toUpperCase(),
            jobTitle,
            language,
            firstName,
            lastName,
            email,
            oldUserId: formUserDetails['userId'],
            statusUser: activeUser as StatusUser,
          })
        ).unwrap();

        dispatch(changeUserID(userData.userId));
        dispatch(changeUserStatus(activeUser as StatusUser));
        await reset(userData);
        setDisabledTabs([]);
        setSaveEnable(false);

        return;
      } catch {
        setSaveEnable(true);
        setDisabledTabs(prevState => prevState);
        setAlertSaveUserDataVisibility(true);

        return;
      }
    } else if (selectedTab === 1) {
      try {
        if (defaultStore?.storeCode) {
          const stores = storeBySalesOrganization
            ?.map(({ storeCode, roles }) => ({
              storeCode,
              roles,
            }))
            .filter(({ storeCode }) => storeCode !== defaultStore.storeCode);
          await dispatch(
            saveEditStores({
              userId: userData.userId.toUpperCase(),
              defaultStoreAndRoles: {
                storeCode: defaultStore.storeCode,
                roles: defaultStore.roles?.map(role => role),
              },
              storesAndRoles: stores,
            })
          ).unwrap();
        }
        redirectTo();
      } catch {
        setAlertSaveStoresVisibility(true);
      }
    }
  };

  const onSaveClick = async (): Promise<void> => {
    await handleSubmit(onFormSubmit)();
  };

  const onFilterChange = (
    key: keyof StoreFilters,
    value: string | RoleFilter
  ): void => {
    setFilters(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onChipDelete = (chip: Chip): void => {
    setInitDefaultStore(false);
    setGetStoresBySales(false);
    setSaveEnable(true);

    dispatch(removeStoresBySale(chip.label));

    setOrganizations(prevState =>
      prevState.filter(organization => organization !== chip.label)
    );
  };

  const onSalesAutoCompleteChange = (sale: string | null): void => {
    if (sale) {
      setOrganizations(prevState => [...new Set([...prevState, sale])]);
      setGetStoresBySales(true);
      setSaveEnable(true);

      if (initDefaultStore) {
        setInitDefaultStore(false);
      }
    }
  };

  const onConfirmUndoModalClick = async (): Promise<void> => {
    if (selectedTab === 0) {
      setSaveEnable(false);
      setActiveUser(formUserDetails.statusUser as StatusUser);
      await setDefaultFormDetails();
    } else {
      setSelectedStore(undefined);
      dispatch(initProfilingDefaultStore());
      await dispatch(getEditStoresAndRoles({ userId: query.userId as string }));

      setDisabledTabs([]);
      setSaveEnable(false);
      setGetStoresBySales(false);
      setInitDefaultStore(false);
      setResetValues(true);
    }

    setAttentionModalVisibility(false);
  };

  const onConfirmBackModalClick = async (): Promise<void> => {
    await onSaveClick();
    setAttentionBackModalVisibility(false);
    redirectTo();
  };

  const onCloseUndoModalClick = (): void => {
    setAttentionModalVisibility(false);
  };

  const onCloseBackModalClick = (): void => {
    setAttentionBackModalVisibility(false);
  };

  const onUndoStores = (): void => {
    dispatch(undoInsertedStores(storeCodesToUndo));
    setUndoStoresSnackbarVisibility(false);
    setStoreCodesToUndo([]);
  };

  const onUndoInsertRoles = (): void => {
    dispatch(undoInsertedRoles(insertedRolesToUndo));
    showInsertedRolesSnackbar(false);
    setInsertedRolesToUndo([]);
  };

  const onUndoDeleteRoles = (): void => {
    dispatch(undoDeletedRoles(deletedRolesToUndo));
    showDeletedRolesSnackbar(false);
    setDeletedRolesToUndo([]);
  };

  const onUndoRoles = (): void => {
    dispatch(undoInsertOrDeleteRoles(rolesToUndo));
    showUndoRolesSnackbar(false);
    setRolesToUndo([]);
  };

  const onUndoSnackbarClose = (): void => {
    setStoreCodesToUndo([]);
    setInsertedRolesToUndo([]);
    setDeletedRolesToUndo([]);
  };

  const onUnConfirmModalClick = async (): Promise<void> => {
    setAttentionModalVisibility(false);
    redirectTo();
  };

  const onBackClick = (): void => {
    if (saveEnable) {
      return setAttentionBackModalVisibility(true);
    }

    redirectTo();
  };

  if (getUserDetailsIsloading) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalAttention
        open={attentionBackModalIsVisible}
        onConfirmClick={onConfirmBackModalClick}
        onUnconfirmClick={onUnConfirmModalClick}
        onClose={onCloseBackModalClick}
        message={t('modal.attention.messageSave')}
        disableConfirm={!saveEnable || confirmDisabled}
      />
      <ModalAttention
        open={attentionModalIsVisible}
        onConfirmClick={onConfirmUndoModalClick}
        onClose={onCloseUndoModalClick}
        message={t('modal.attention.message')}
      />
      <Tabs
        mt="18px"
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        values={tabs}
        disabledTabs={disabledTabs}
      />
      {selectedTab === 0 ? (
        <>
          <StyledSwitchWrapper>
            <UISwitch
              checked={activeUser === 'Active'}
              checkedLabel={t('activeUser')}
              unCheckedLabel={t('inactiveUser')}
              onChange={(): void => {
                setSaveEnable(prevState => !prevState);
                setActiveUser(prevState =>
                  prevState === 'Active' ? 'Inactive' : 'Active'
                );
              }}
            />
          </StyledSwitchWrapper>
          <Form>
            <TextFieldController
              required
              uppercase
              name="userId"
              control={control}
              value={watch('userId')}
              isErrorVisible={getValues('userId') === ''}
            />
            <SelectController
              name="jobTitle"
              control={control}
              options={userDetails['jobTitles'] || []}
              value={watch('jobTitle') || ''}
              // isErrorVisible={getValues('jobTitle') === ''}
            />
            <TextFieldController
              required
              name="firstName"
              control={control}
              value={watch('firstName')}
              isErrorVisible={getValues('firstName') === ''}
            />
            <TextFieldController
              required
              name="lastName"
              control={control}
              value={watch('lastName')}
              isErrorVisible={getValues('lastName') === ''}
            />
            <TextFieldController
              required
              name="email"
              control={control}
              value={watch('email')}
              isErrorVisible={getValues('email') !== '' && !isEmailFormatValid}
            />
            <SelectController
              required
              name="language"
              control={control}
              options={(i18n.languages || []) as string[]}
              value={watch('language') || ''}
              isErrorVisible={getValues('language') === ''}
            />
          </Form>
        </>
      ) : (
        <>
          <ModalSelectStore
            open={selectStoreModalIsVisible}
            setStoreCodesToUndo={setStoreCodesToUndo}
            setAlertUndoSnackbarVisibility={setUndoStoresSnackbarVisibility}
            setSaveEnable={setSaveEnable}
            onClose={(): void => setSelectStoreModalVisibility(false)}
          />
          <ModalRole
            type={roleModal.type}
            open={roleModal.open}
            roles={
              roleModal.type === 'ADD'
                ? roles
                : [...new Set([...roles, ...selectedStoresRoles])]
            }
            setSaveEnable={setSaveEnable}
            selectedStores={selectedStores}
            setSelectedStores={setSelectedStores}
            setRolesToUndo={setRolesToUndo}
            setInsertedRolesToUndo={setInsertedRolesToUndo}
            setDeletedRolesToUndo={setDeletedRolesToUndo}
            showInsertedRolesSnackbar={showInsertedRolesSnackbar}
            showDeletedRolesSnackbar={showDeletedRolesSnackbar}
            showUndoRolesSnackbar={showUndoRolesSnackbar}
            onClose={(): void => {
              setSelectedStores([]);
              setRoleModalState(prevState => ({
                ...prevState,
                rolesChecked: [],
                open: false,
              }));
            }}
          />
          <Form margin="0 0 12px">
            <StyledAutocompleteWrapper>
              <Typography font="medium">{t('defaultStore')}</Typography>
              <UIAutoCompleteStores
                inputPlaceholder={t('changeStore')}
                selectedStore={selectedStore}
                setSaveEnable={setSaveEnable}
                setSelectedStore={setSelectedStore}
              />
            </StyledAutocompleteWrapper>
            <StyledAutocompleteWrapper>
              <Typography font="medium">{t('salesOrganization')}</Typography>
              <Autocomplete
                key="sales-organizations"
                value={sale}
                onChange={(_e, sale): void => {
                  onSalesAutoCompleteChange(sale);
                }}
                options={userDetails['salesOrganization'] || []}
                getOptionLabel={(option): string => option || ''}
                getOptionSelected={(option, value): boolean => option === value}
                renderInput={(params): JSX.Element => (
                  <TextField {...params} variant="outlined" />
                )}
              />
              <StyledChipList
                chips={chips}
                onChipDelete={(chip): void => onChipDelete(chip)}
              />
            </StyledAutocompleteWrapper>
            <StyledButton
              label={t('add.store')}
              variant="text"
              endIcon={<AddCircleOutlineIcon />}
              onClick={(): void => setSelectStoreModalVisibility(true)}
              disabled={!defaultStore?.storeCode}
            />
          </Form>
          {storesIsLoading ? (
            <StyledLoaderWrapper>
              <UILoader />
            </StyledLoaderWrapper>
          ) : (
            <>
              {defaultStore?.storeCode && (
                <>
                  <StyledFilterWrapper>
                    <StyledButtonWithIcon
                      label={t('addRoles')}
                      disabled={selectedStores.length <= 1}
                      endIcon={<AddCircleOutlineIcon />}
                      onClick={(): void =>
                        setRoleModalState({
                          open: true,
                          type: 'ADD',
                          roles,
                        })
                      }
                    />
                    <StyledButtonWithIcon
                      label={t('deleteRoles')}
                      disabled={selectedStores.length <= 1}
                      endIcon={<RemoveCircleOutlineIcon />}
                      onClick={(): void =>
                        setRoleModalState({
                          open: true,
                          type: 'DELETE',
                          roles: selectedStoresRoles,
                        })
                      }
                    />
                    <SearchBarLight
                      label=""
                      value={filters.sales}
                      placeholder={t('profiling.filterBySalesOrganizations')}
                      disabled={false}
                      onSearch={(): void => {}}
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => onFilterChange('sales', e.target.value)}
                      loading={false}
                      hideButton={true}
                    />
                    <SearchBarLight
                      label=""
                      value={filters.storeCode}
                      placeholder={t('profiling.filterByStoreCode')}
                      disabled={false}
                      onSearch={(): void => {}}
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => onFilterChange('storeCode', e.target.value)}
                      loading={false}
                      hideButton={true}
                    />
                    <UISelect
                      title=""
                      placeholder={t('profiling.filterByPrivilege')}
                      variant="outlined"
                      onChange={(
                        e: React.ChangeEvent<{ value: unknown }>
                      ): void =>
                        onFilterChange('role', e.target.value as RoleFilter)
                      }
                      value={filters.role}
                      defaultValue={'All'}
                      values={rolesOptions}
                    />
                  </StyledFilterWrapper>
                  <Tables>
                    <ProfilingStoreRolesList
                      listCurrentPage={listCurrentPage}
                      setListCurrentPage={setListCurrentPage}
                      paginationStores={paginationStores}
                      setSelectedStores={setSelectedStores}
                      selectedStores={selectedStores}
                      setSaveEnable={setSaveEnable}
                      storesChecked={storesChecked}
                      setAllStoresChecked={setAllStoresChecked}
                    />
                  </Tables>
                </>
              )}
            </>
          )}
        </>
      )}
      <CTAContainer
        type="UNDO"
        onConfirmClick={(): Promise<void> => onSaveClick()}
        onQuitClick={onBackClick}
        onUndoClick={(): void => {
          setAttentionModalVisibility(true);
        }}
        disabled={saveEditDataIsLoading || saveEditStoresIsLoading}
        loadingConfirm={saveEditDataIsLoading || saveEditStoresIsLoading}
        undoDisabled={
          !saveEnable || saveEditDataIsLoading || saveEditStoresIsLoading
        }
        disabledConfirm={!saveEnable || confirmDisabled}
      />

      <AlertSnackbar
        open={alertSaveUserData && !!saveEditDataHasError}
        setIsOpen={setAlertSaveUserDataVisibility}
        message={saveEditDataHasError?.body}
      />
      <AlertSnackbar
        open={alertSaveStores && !!saveEditStoresHasError}
        setIsOpen={setAlertSaveStoresVisibility}
        message={saveEditDataHasError?.body}
      />
      <AlertUndoSnackbar
        open={insertedRolesSnackbarIsVisible}
        setIsOpen={showInsertedRolesSnackbar}
        onUndoClick={onUndoInsertRoles}
        onClose={onUndoSnackbarClose}
        message={t('notification.itemAdded')}
        margin={'0 0 100px'}
      />
      <AlertUndoSnackbar
        open={deletedRolesSnackbarIsVisible}
        setIsOpen={showDeletedRolesSnackbar}
        onUndoClick={onUndoDeleteRoles}
        onClose={onUndoSnackbarClose}
        message={t('notification.itemRemoved')}
        margin={'0 0 100px'}
      />
      <AlertUndoSnackbar
        open={undoRolesSnackbarIsVisible}
        setIsOpen={showUndoRolesSnackbar}
        onUndoClick={onUndoRoles}
        onClose={onUndoSnackbarClose}
        message={t('notification.itemEdited')}
        margin={'0 0 100px'}
      />
      <AlertUndoSnackbar
        open={undoStoresSnackbarIsVisible}
        setIsOpen={setUndoStoresSnackbarVisibility}
        onUndoClick={onUndoStores}
        onClose={onUndoSnackbarClose}
        message={t('notification.itemAdded')}
        margin={'0 0 100px'}
      />
    </>
  );
};

export default PageControlUsersEdit;
