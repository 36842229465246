import { ProcessStatus } from '@/api/process';
import { AppRoutes } from '@/app/routers';
import ExpiringSessionModal from '@/components/SessionExpiring/ExpiringSessionModal';
import { setShowSessionExpiredModal } from '@/features/expiringSession/sessionExpired';
import {
  setExpiringSessionModalIsClosed,
  setShowExpiringSessionModal,
} from '@/features/expiringSession/expiringSession';
import { palette } from '@/theme';
import { Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useInterval } from 'react-use';
import styled from 'styled-components';

interface TtlMessageProps {
  process: string;
}

interface Ttl {
  hours: number;
  minutes: number;
}

const TtlMessageContainer = styled.div`
  padding: 0 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 8px;
`;
const StyledErrorIcon = styled(ErrorIcon)`
  height: 30px;
  width: 30px;
`;

export const TtlMessage: FC<TtlMessageProps> = ({
  process,
}: TtlMessageProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const processStatus = useSelector(state => state.processStatus);
  const { showExpiringSessionModal, expiringSessionModalIsClosed } =
    useSelector(state => state.expiringSession);

  const [ttl, setTtl] = useState<number>(0);
  const [ttlForMessage, setTtlForMessage] = useState<Ttl>({
    hours: 0,
    minutes: 0,
  });
  const [singleProcess, setSingleProcess] = useState<ProcessStatus | undefined>(
    undefined
  );

  /* identify which process we are on */
  useEffect(() => {
    setSingleProcess(processStatus.find(el => el.process === process));
  }, [process, processStatus, singleProcess]);

  /* timeout for session will expire in less than 5min */
  useInterval(() => {
    if (singleProcess && (singleProcess.ttl || 0) >= 0) {
      const lockDateMoment = moment(singleProcess?.lockDate);
      const nowMoment = moment();
      const timeInSession = nowMoment.diff(lockDateMoment, 'seconds');
      if (
        ttl - timeInSession <= 300 &&
        !showExpiringSessionModal &&
        !expiringSessionModalIsClosed
      ) {
        dispatch(setShowExpiringSessionModal(true));
      }
    }
  }, 1000);

  /* timeout for redirect to homepage when session is expired */
  useInterval(async () => {
    if (singleProcess && (singleProcess.ttl || 0) >= 0) {
      const lockDateMoment = moment(singleProcess?.lockDate);
      const nowMoment = moment();
      const timeInSession = nowMoment.diff(lockDateMoment, 'seconds');
      if (timeInSession >= ttl) {
        await dispatch(setShowSessionExpiredModal(true));
        history.push(AppRoutes.INTRO);
      }
    }
    if (singleProcess && singleProcess.isLocked === false) {
      await dispatch(setShowSessionExpiredModal(true));
      history.push(AppRoutes.INTRO);
    }
  }, 1000);

  useEffect(() => {
    if (singleProcess) {
      const ttl = singleProcess.ttl || 0;
      setTtl(ttl);

      /* if Time To Leave is bigger than 0, show the alert on the page */
      if (ttl > 0) {
        /* convert Time To Leave in hours and minutes */
        const hours = ttl / 3600;
        const ttlHours = hours > 1 ? hours : 0;
        const ttlMinutes = ttl / 60 - ttlHours * 60;
        setTtlForMessage({ hours: ttlHours, minutes: ttlMinutes });
      }
    }
    return () => {
      dispatch(setExpiringSessionModalIsClosed(false));
    };
  }, [singleProcess]);

  return singleProcess && singleProcess.ttl && singleProcess.ttl > 0 ? (
    <TtlMessageContainer>
      <StyledErrorIcon htmlColor={palette.colors.yellow} />
      <Typography variant={'subtitle2'}>
        {ttlForMessage.hours > 0 && ttlForMessage.minutes > 0
          ? t('ttlHoursAndMinutesMessage', {
              ttlHours: ttlForMessage.hours,
              ttlMinutes: ttlForMessage.minutes,
            })
          : ttlForMessage.hours > 0 && ttlForMessage.minutes === 0
          ? t('ttlHoursMessage', { ttlHours: ttlForMessage.hours })
          : ttlForMessage.hours === 0 && ttlForMessage.minutes > 0
          ? t('ttlMinutesMessage', { ttlMinutes: ttlForMessage.minutes })
          : t('ttlSecondsMessage', { ttlSeconds: singleProcess.ttl })}
      </Typography>
      {!expiringSessionModalIsClosed && showExpiringSessionModal && (
        <ExpiringSessionModal />
      )}
    </TtlMessageContainer>
  ) : (
    <></>
  );
};
export default TtlMessage;
