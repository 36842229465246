import { FC, useEffect, useState } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';

import { useTranslation } from 'react-i18next';

import {
  addCycleCountFilteredByUPC,
  getBrandsByStore,
  setCycleCountFilteredByUpc,
  initCycleCountState,
  setFilterSelected,
} from '@/features/cycleCount/cycleCountSlice';

import TabCycleCount from '@/components/CycleCount/TabCycleCount/TabCycleCount';
import {
  CycleCountService,
  CycleDetailsItems,
  ElasticHandlerRestService,
} from '@/api/receive';
import GenericFilter from '@/components/CycleCount/GenericFilter/GenericFilter';
import { StyledPageContainer, StyledTitle } from './style';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { AppRoutes } from '@/app/routers';
import { useHistory } from 'react-router';
import ListCCSelect from '@/components/CycleCount/ListCCSelect/ListCCSelect';
import UpcLI from '@/components/CycleCount/UpcLI/UpcLI';
import { useAsync, useMount, useUpdateEffect } from 'react-use';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';

const PageCycleCountFilterUPC: FC = () => {
  const [searchedUpc, setSearchedUpc] = useState<string>('');
  const [upcList, setUpcList] = useState<Array<string>>([]);
  const [requestBodyUpcList, setRequestBodyUpcList] = useState<Array<string>>(
    []
  );
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [checkedElements, setCheckedElements] = useState<{
    [k: string]: boolean;
  }>({});

  const [latestUpc, setLatestUpc] = useState<string>('');

  const filterNr = 1;

  const history = useHistory();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useMount(() => {
    dispatch(setFilterSelected(filterNr));
  });

  const { filterByUpc, selectedUpc } = useSelector(state => state?.cycleCount);
  const isSohProcess = useSelector(state => state.cycleCount.sohLocked);
  const { store } = useSelector(state => state.currentStore);

  const debounceFn = async (): Promise<void> => {
    try {
      const { filterValueList } =
        await ElasticHandlerRestService.elastichandlerSearchFromUpcByFilterAutocomplete(
          {
            filterType: 'upcCode',
            filterValue: searchedUpc,
          }
        );

      setUpcList(
        filterValueList?.map(({ filterValue }) => filterValue || '') || []
      );
    } catch (e) {
      console.error(e);
    }
  };

  const { brandIsLoading, selectedBrands } = useSelector(
    state => state.cycleCount
  );

  useEffect(() => {
    if (brandIsLoading === undefined && selectedBrands?.length === 0) {
      dispatch(getBrandsByStore());
    }
  }, [dispatch, brandIsLoading, selectedBrands?.length]);

  const curDate = new Date();

  const curY = curDate.getFullYear();
  const curM = (curDate.getMonth() + 1).toString().padStart(2, '0');
  const curD = curDate.getDate().toString().padStart(2, '0');

  useAsync(async () => {
    if (requestBodyUpcList.length > 0) {
      try {
        const { productDetailsItems } =
          await CycleCountService.cyclecountSearchProductByFilter({
            requestBody: {
              date: `${curY}${curM}${curD}`,
              storeCode: store!.storeCode!,
              upcList: requestBodyUpcList,
            },
          });

        if (productDetailsItems) {
          dispatch(addCycleCountFilteredByUPC(productDetailsItems));
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [requestBodyUpcList.length]);

  useUpdateEffect(() => {
    if (
      filterByUpc &&
      !filterByUpc.some(({ upcCode }) => upcCode === latestUpc)
    ) {
      setOpenSnackbar(true);
    }
  }, [filterByUpc]);

  const onAutoCompleteOptionSelect = async (upc: string): Promise<void> => {
    if (upc !== '') {
      if (selectedUpc.length > 0) {
        const upcCodes = filterByUpc?.map(({ upcCode }) => upcCode) || [];
        setRequestBodyUpcList(p => [...new Set([...p, ...upcCodes, upc])]);
      } else {
        setRequestBodyUpcList(p => [...new Set([...p, upc])]);
      }
      setLatestUpc(upc);
    }
  };

  const checkUpcs = (): string[] => {
    const upcCodes = Object.entries(checkedElements);
    const checkedUpcCodes: string[] = [];

    for (const [upcCode, isChecked] of upcCodes) {
      if (isChecked) {
        checkedUpcCodes.push(upcCode);
      }
    }

    return checkedUpcCodes;
  };

  const applyClickHandler = (): void => {
    const checkedUpcCodes = checkUpcs();
    dispatch(setCycleCountFilteredByUpc(checkedUpcCodes));
    if (isSohProcess) {
      history.push(AppRoutes.SOH_ALIGNMENT_FILTER_SUMMARY);
    } else {
      history.push(AppRoutes.CYCLE_COUNT_FILTER_SUMMARY);
    }
  };

  return (
    <StyledPageContainer>
      <StyledTitle font="heavy" size="lg">
        {isSohProcess ? t('filterYourSoh') : t('cycleCount.filtertitle')}
      </StyledTitle>

      <TabCycleCount index={filterNr} />

      <GenericFilter
        debounceFn={debounceFn}
        list={upcList}
        onAutoCompleteOptionSelect={onAutoCompleteOptionSelect}
        setChangeElement={setSearchedUpc}
        filterTitle={t('cycleCount.filter.UPC.label')}
        filterPlaceholder={t('cycleCount.searchByUpc')}
      />

      <ListCCSelect
        data={filterByUpc as CycleDetailsItems[]}
        keyName="upcCode"
        checkedElements={checkedElements}
        exportCheckedElements={setCheckedElements}
        component={(el, key, isChecked): JSX.Element => (
          <UpcLI
            el={el}
            keyName={key}
            setChecked={setCheckedElements}
            forceChecked={isChecked}
          />
        )}
      />

      <CTAContainer
        type="APPLY"
        disabledMainAction={checkUpcs().length === 0}
        onClick={(): void => applyClickHandler()}
        onBackClick={(): void => {
          if (isSohProcess) {
            history.push(AppRoutes.SOH_ALIGNMENT);
          } else {
            dispatch(initCycleCountState());
            history.push(AppRoutes.CYCLE_COUNT);
          }
        }}
      />

      <AlertSnackbar
        open={openSnackbar}
        message={t('cycleCount.otherStore.message', { type: 'UPC' })}
        setIsOpen={setOpenSnackbar}
      />
    </StyledPageContainer>
  );
};

PageCycleCountFilterUPC.displayName = 'PageCycleCountFilterUPC';

export default PageCycleCountFilterUPC;
