import { AppRoutes } from '@/app/routers';
import { Menu } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const AdminUploadFileMenu = ({
  anchorEl,
  setAnchorEl,
}: {
  anchorEl: null | HTMLElement;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
}): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const openUploadFileMenu = Boolean(anchorEl);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleUploadNewUserFile = (): void => {
    handleClose();
    history.push(AppRoutes.MANAGE_USERS_UPLOAD);
  };

  const handleUploadDeactivationFile = (): void => {
    handleClose();
    history.push(AppRoutes.MANAGE_DEACTIVATION_USERS_UPLOAD);
  };

  return (
    <Menu
      open={openUploadFileMenu}
      id={'upload-file-menu'}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <MenuItem onClick={handleUploadNewUserFile}>
        {t('controlUsers.upload.new.users.label')}
      </MenuItem>
      <MenuItem onClick={handleUploadDeactivationFile}>
        {t('controlUsers.upload.users.deactivation.label')}
      </MenuItem>
    </Menu>
  );
};

export default AdminUploadFileMenu;
