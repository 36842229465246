import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@/theme';
import GlobalStyle from '../globalStyles';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '@/AppInsights';
import Grow from '@material-ui/core/Grow';

import { AppRoutes, Routes } from '@/app/routers';

import { useAppDispatch } from '@/app/store';

import CssBaseline from '@material-ui/core/CssBaseline';

import { AppContainer } from '@/components/layout/AppContainer';
import { ErrorBoundary } from '@/components/ui/Error';
import { BFFOpenAPI, Device, ProcessOpenAPI, UsersOpenAPI } from '@/api';
import { ReceiveOpenAPI } from '@/api';
import { RepriceOpenAPI } from '@/api';
import { updateDeviceStatus } from '@/features/devices/devicesSlice';
import { unlockDeliveries } from '@/features/deliveries/deliveriesSlice';
import { DeviceStatus } from '@/types/enum';
import useUnload from '@/hooks/useUnload';
import MQTTProvider from '@/context/MQTT';
import { useSubscribeDeviceStatus } from '@/hooks/mqtt/useSubscribeDeviceStatus';
import { changeRecallStatus } from '@/features/recall/recallSlice';
import { SnackbarProvider } from 'notistack';
import { useAsync, useInterval } from 'react-use';

import { useLocation } from 'react-router';
import { getAppStatus } from '@/utils/getAppStatus';
import { useSelector } from '@/hooks/useSelector';
import { updateMaintenanceState } from '@/features/maintenance/maintenanceSlice';
import useHandleProcess from '@/hooks/useHandleProcess';

import { SignalRContextProvider, useSignalRContext } from '@/context/signalR';

BFFOpenAPI.BASE =
  process.env.REACT_APP_SRO_API_URL?.replace(/\/$/, '') || BFFOpenAPI.BASE;

ReceiveOpenAPI.BASE =
  process.env.REACT_APP_BASE_API_URL?.replace(/\/$/, '') || ReceiveOpenAPI.BASE;

RepriceOpenAPI.BASE =
  process.env.REACT_APP_REPRICE_API_URL?.replace(/\/$/, '') ||
  RepriceOpenAPI.BASE;

ProcessOpenAPI.BASE =
  process.env.REACT_APP_PROCESS_API_URL?.replace(/\/$/, '') ||
  ProcessOpenAPI.BASE;

UsersOpenAPI.BASE =
  process.env.REACT_APP_USERSMNG_API_URL?.replace(/\/$/, '') ||
  UsersOpenAPI.BASE;

BFFOpenAPI.TOKEN = sessionStorage.getItem('userToken') || '';
ReceiveOpenAPI.TOKEN = sessionStorage.getItem('userToken') || '';
RepriceOpenAPI.TOKEN = sessionStorage.getItem('userToken') || '';
ProcessOpenAPI.TOKEN = sessionStorage.getItem('userToken') || '';
UsersOpenAPI.TOKEN = sessionStorage.getItem('userToken') || '';

const useStyles = makeStyles({
  root: {
    minWidth: '80vw',
    width: '100%',
  },
  containerRoot: {
    fontSize: 2000,
    textAlign: 'center',
    padding: 16,
    marginBottom: 80,
  },
});

export const App: FC = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { dataStart, dataEnd, message } = useSelector(
    state => state.maintenance
  );
  const subscribeDevice = useSubscribeDeviceStatus();
  const location = useLocation();
  const handleProcess = useHandleProcess();
  const { connected: isSignalrConnected, disconnect: disconnectSignalr } =
    useSignalRContext();
  const salesPosProcessIsLocked = useSelector(state => state.pos.processLocked);
  const returnPosProcessIsLocked = useSelector(
    state => state.returnPos.processLocked
  );

  useUnload(e => {
    e.preventDefault();

    const deliveriesInStorage = sessionStorage.getItem('selectedDeliveries');
    const deviceInStorage = sessionStorage.getItem('deviceInUse');

    if (salesPosProcessIsLocked) {
      handleProcess({ process: 'SLEX', isLocked: false });
    }

    if (returnPosProcessIsLocked) {
      handleProcess({ process: 'RTPS', isLocked: false });
    }

    if (deviceInStorage) {
      const device = JSON.parse(deviceInStorage) as Device;

      dispatch(
        updateDeviceStatus({
          ...device,
          statusMode: 'read',
          statusDevice: DeviceStatus.ONLINE,
        })
      );
      subscribeDevice.publish(device.deviceId, undefined);
    }

    if (deliveriesInStorage) {
      dispatch(unlockDeliveries());
    }

    if (isSignalrConnected) {
      disconnectSignalr();
    }

    dispatch(changeRecallStatus());
  });

  const checkAppStatus = async (): Promise<void> => {
    const { status, ...rest } = await getAppStatus();

    if (
      rest.dataStart !== dataStart ||
      rest.dataEnd !== dataEnd ||
      rest.message !== message
    ) {
      dispatch(updateMaintenanceState({ status, ...rest }));
    }

    if (status === 0 && location.pathname === AppRoutes.MAINTENANCE) {
      window.location.replace(AppRoutes.INTRO);
    }

    if (status === 1 && location.pathname !== AppRoutes.MAINTENANCE) {
      window.location.replace(AppRoutes.MAINTENANCE);
      sessionStorage.clear();
    }
  };

  useAsync(async () => {
    await checkAppStatus();
  }, []);

  useInterval(async () => {
    await checkAppStatus();
  }, 5000);

  return (
    <ThemeProvider>
      <AppInsightsContext.Provider value={reactPlugin}>
        <GlobalStyle />
        <CssBaseline />
        <ErrorBoundary>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={6000}
            classes={{
              root: classes.root,
              containerRoot: classes.containerRoot,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            // @ts-ignore
            TransitionComponent={Grow}
          >
            <MQTTProvider
              brokerAddress={process.env.REACT_APP_BROKER_ADDRESS || ''}
            >
              <SignalRContextProvider>
                <AppContainer>
                  <Routes />
                </AppContainer>
              </SignalRContextProvider>
            </MQTTProvider>
          </SnackbarProvider>
        </ErrorBoundary>
      </AppInsightsContext.Provider>
    </ThemeProvider>
  );
};
