import { useAppDispatch } from '@/app/store';
import { ModalAttention } from '@/components/layout/ModalAttention';
import { PaginationList } from '@/components/layout/PaginationList';
import { UICheckbox } from '@/components/ui/Checkbox';

import { Typography } from '@/components/ui/Typography';

import {
  removeUser,
  removeUserFromRedux,
} from '@/features/profiling/usersSlice';
import RecapUsersRow from '@/pages/ControlPanel/PageControlUsers/components/RecapUsersRow';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { StyledGrid, StyledSimplifiedGridHeader } from './style';
import { ProfilingUserRecapProps, TUserSelect } from './types';
import { User } from '@/api/users';

const RecapUsersList: React.FC<ProfilingUserRecapProps> = ({
  users,
  setAlertSnackbarVisibility,
  exportUsersSelected,
  setUsersSelected,
  usersSelected,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [id, setUserId] = useState<string>();
  const [userSelected, setUserSelected] = useState<TUserSelect | undefined>();

  const [allUsersSelected, setAllUsersSelected] = useState<boolean>(true);

  const { roles } = useSelector(state => state.user);

  const [attentionModalIsVisible, setAttentionModalVisibility] =
    useState<boolean>(false);

  const onModalCloseClick = (): void => {
    setAttentionModalVisibility(false);
    setUserId(undefined);
  };

  useUpdateEffect(() => {
    if (userSelected) {
      setUsersSelected(prev => ({
        ...prev,
        ...{ [userSelected.user]: userSelected.checked },
      }));
    }
  }, [userSelected]);

  useUpdateEffect(() => {
    exportUsersSelected?.(
      Object.keys(usersSelected).filter(k => usersSelected[k])
    );
    setAllUsersSelected(
      Object.values(usersSelected).filter(value => value).length ===
        users.length
    );
  }, [usersSelected]);

  const onConfirmRemoveClick = async (): Promise<void> => {
    try {
      if (id) {
        await dispatch(removeUser({ userId: id })).unwrap();
        dispatch(removeUserFromRedux(id));
        setAttentionModalVisibility(false);
      }
    } catch (err) {
      setAlertSnackbarVisibility?.(true);
    }
  };

  const changeHandler = (): void => {
    if (allUsersSelected) {
      setUsersSelected({});
    }
    setAllUsersSelected(!allUsersSelected);
  };

  useUpdateEffect(() => {
    if (allUsersSelected && users) {
      const selected = users.reduce((acc: { [k: string]: boolean }, user) => {
        if (user.userId) {
          acc = {
            ...acc,
            [user.userId]: true,
          };
        }
        return acc;
      }, {});

      setUsersSelected(selected);
    }
  }, [allUsersSelected]);

  return (
    <>
      <ModalAttention
        open={attentionModalIsVisible}
        onConfirmClick={onConfirmRemoveClick}
        onClose={onModalCloseClick}
        message={t('attentionDeleteUser')}
      />
      <StyledGrid>
        <StyledSimplifiedGridHeader>
          <Typography font="heavy">
            {users?.length > 0 && (
              <UICheckbox
                color="primary"
                onChange={changeHandler}
                checked={allUsersSelected}
              />
            )}
          </Typography>

          <Typography font="heavy" margin={'0'}>
            {t('userId')}
          </Typography>
          <Typography font="heavy">{t('fullName')}</Typography>
        </StyledSimplifiedGridHeader>

        <PaginationList
          data={users}
          pageSize={20}
          renderItem={(user: User): JSX.Element =>
            user && (
              <RecapUsersRow
                {...user}
                key={user.userId}
                setUserId={setUserId}
                setAttentionModalVisibility={setAttentionModalVisibility}
                exportUserSelected={setUserSelected}
                checked={usersSelected?.[user?.userId || ''] || false}
                isCheckboxVisible={roles?.includes('Admin')}
              />
            )
          }
        />
      </StyledGrid>
    </>
  );
};

export default RecapUsersList;
