import { FC, useState } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { AppRoutes } from '@/app/routers';
import { useHistory } from 'react-router';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { useTranslation } from 'react-i18next';
import { UsersService } from '@/api/users';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { DropzoneWrapper } from './style';

const PageStoreMigrator: FC = () => {
  const [file, setFile] = useState<FileList | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadErrorSnackbar, setUploadErrorSnackbar] =
    useState<boolean>(false);
  const [uploadErrorSnackbarMessage, setUploadErrorSnackbarMessage] =
    useState<string>('');

  const history = useHistory();
  const { t } = useTranslation();

  const uploadClickHandler = (files: FileList | undefined): void => {
    if (files && files.length > 0) {
      const csv = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(csv);

      reader.onload = async (): Promise<void> => {
        const result = reader?.result as string;

        setIsLoading(true);
        try {
          const fileName = file?.[0]?.name || '';
          const contentType = file?.[0]?.type || '';
          const fileContent = result!.split(',')?.[1];
          await UsersService.uploadDeactivationUsers({
            requestBody: {
              sheetNo: 0,
              originalFilename: fileName,
              content: fileContent,
              contentType,
              name: 'file',
            },
          });

          setIsLoading(false);

          history.push(AppRoutes.MANAGE_USERS);
        } catch (e) {
          setUploadErrorSnackbar(true);
          // @ts-ignore
          setUploadErrorSnackbarMessage(e.body.errorMessage || e.body.message);
          setIsLoading(false);
        }
      };
    }
  };
  const acceptedFiles = [
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
  ];

  const getFileAddedMessage = (fileName: string): string =>
    t('usersUpload.dragDropArea.add', { fileName });
  const getFileRemovedMessage = (fileName: string): string =>
    t('usersUpload.dragDropArea.remove', { fileName });
  const getFileLimitExceedMessage = (fileName: string): string =>
    t('usersUpload.dragDropArea.error.dimension', { file: fileName });

  const getDropRejectMessage = (
    rejectedFile: File,
    acceptedFiles: string[],
    maxFileSize: number
  ): string => {
    if (!acceptedFiles.includes(rejectedFile.type)) {
      return t('usersUpload.dragDropArea.error.extension', {
        file: rejectedFile.name,
      });
    }
    if (rejectedFile.size > maxFileSize) {
      return t('usersUpload.dragDropArea.error.dimension', {
        file: rejectedFile.name,
      });
    }
    return t('usersUpload.dragDropArea.error.base', {
      file: rejectedFile.name,
    });
  };

  return (
    <div>
      <DropzoneWrapper>
        <DropzoneArea
          acceptedFiles={acceptedFiles}
          filesLimit={1}
          showFileNames
          //@ts-ignore
          onChange={(loadedFile: FileList | undefined): void => {
            setFile(loadedFile);
          }}
          dropzoneProps={{ multiple: false }}
          dropzoneText={t('usersUpload.dragDropArea.title')}
          getFileAddedMessage={getFileAddedMessage}
          getFileRemovedMessage={getFileRemovedMessage}
          //@ts-ignore
          getFileLimitExceedMessage={getFileLimitExceedMessage}
          getDropRejectMessage={getDropRejectMessage}
          maxFileSize={10000000}
        />
      </DropzoneWrapper>

      <ErrorSnackbar
        open={uploadErrorSnackbar}
        setIsOpen={setUploadErrorSnackbar}
        errorMessage={uploadErrorSnackbarMessage}
      />

      <CTAContainer
        type="TWO_BUTTONS"
        mainButtonLabel="Send"
        loading={isLoading}
        disabledMainAction={typeof file === 'undefined' || file!.length === 0}
        onClick={(): void => uploadClickHandler(file)}
        onBackClick={(): void => history.push(AppRoutes.MANAGE_USERS)}
        label={t('back')}
      />
    </div>
  );
};

export default PageStoreMigrator;
