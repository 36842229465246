/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssignStoreAndRolesRequest } from '../models/AssignStoreAndRolesRequest';
import type { EncodedRequest } from '../models/EncodedRequest';
import type { PaginatedSearchUsersRequest } from '../models/PaginatedSearchUsersRequest';
import type { PaginatedSearchUsersResponse } from '../models/PaginatedSearchUsersResponse';
import type { UploadDeactivationUsers } from '../models/UploadDeactivationUsers';
import type { UsersRolesRequest } from '../models/UsersRolesRequest';
import { request as __request } from '../core/request';

export class UsersService {
  /**
   * /upload/deactivation
   * Accept a .xlsx or .xlsx file and process it
   * @returns UploadDeactivationUsers OK
   * @throws ApiError
   */
  public static async uploadDeactivationUsers({
    requestBody,
  }: {
    requestBody: UploadDeactivationUsers;
  }): Promise<UploadDeactivationUsers> {
    const result = await __request({
      method: 'PUT',
      path: `/users/upload/deactivation`,
      body: requestBody,
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /search
   * Retrieve the users using data filters.
   * @returns PaginatedSearchUsersResponse OK
   * @throws ApiError
   */
  public static async searchUsers({
    requestBody,
  }: {
    requestBody: PaginatedSearchUsersRequest;
  }): Promise<PaginatedSearchUsersResponse> {
    const result = await __request({
      method: 'POST',
      path: `/users/search`,
      body: requestBody,
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /roles/remove
   * Remove the roles to users
   * @returns any OK
   * @throws ApiError
   */
  public static async removeUsersRoles({
    requestBody,
  }: {
    requestBody: UsersRolesRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/users/roles/remove`,
      body: requestBody,
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /roles/remove/encoded
   * Remove the roles to users
   * @returns any OK
   * @throws ApiError
   */
  public static async removeUsersRolesEncoded({
    requestBody,
  }: {
    requestBody: EncodedRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/users/roles/remove/encoded`,
      body: requestBody,
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /roles/assign
   * Assigns roles to users for specific stores
   * @returns any OK
   * @throws ApiError
   */
  public static async assignUsersRoles({
    requestBody,
  }: {
    requestBody: AssignStoreAndRolesRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/users/roles/assign`,
      body: requestBody,
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /roles/assign/encoded
   * Assigns roles to users for specific stores
   * @returns any OK
   * @throws ApiError
   */
  public static async assignUsersRolesEncoded({
    requestBody,
  }: {
    requestBody: EncodedRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/users/roles/assign/encoded`,
      body: requestBody,
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /roles/add
   * Adds the roles to users
   * @returns any OK
   * @throws ApiError
   */
  public static async addUsersRoles({
    requestBody,
  }: {
    requestBody: UsersRolesRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/users/roles/add`,
      body: requestBody,
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /roles/add/encoded
   * Adds the roles to users
   * @returns any OK
   * @throws ApiError
   */
  public static async addUsersRolesEncoded({
    requestBody,
  }: {
    requestBody: EncodedRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/users/roles/add/encoded`,
      body: requestBody,
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /download
   * Returns a CSV file of users.
   * @returns any CSV file generated successfully.
   * @throws ApiError
   */
  public static async downloadUsers({
    requestBody,
  }: {
    requestBody: PaginatedSearchUsersRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/users/download`,
      body: requestBody,
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /{userId}
   * Delete the user
   * @returns any OK
   * @throws ApiError
   */
  public static async deleteUser({ userId }: { userId: string }): Promise<any> {
    const result = await __request({
      method: 'DELETE',
      path: `/users/${userId}`,
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }
}
