import { Menu } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

const UserRowActionMenu = ({
  anchorEl,
  setAnchorEl,
  onEditUser,
  onDeleteUser,
  onAddRoles,
  onDeleteRoles,
}: {
  anchorEl: null | HTMLElement;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
  onEditUser: () => void;
  onDeleteUser: () => void;
  onAddRoles: () => void;
  onDeleteRoles: () => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const openUploadFileMenu = Boolean(anchorEl);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleEditUser = (): void => {
    handleClose();
    onEditUser();
  };

  const handleDeleteUser = (): void => {
    handleClose();
    onDeleteUser();
  };

  const handleAddRoles = (): void => {
    handleClose();
    onAddRoles();
  };

  const handleDeleteRoles = (): void => {
    handleClose();
    onDeleteRoles();
  };

  return (
    <Menu
      open={openUploadFileMenu}
      id={'action-menu'}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <MenuItem onClick={handleEditUser}>
        {t('controlUsers.actions.editUser')}
      </MenuItem>
      <MenuItem onClick={handleDeleteUser}>
        {t('controlUsers.actions.deleteUser')}
      </MenuItem>
      <MenuItem onClick={handleAddRoles}>
        {t('controlUsers.actions.addRoles')}
      </MenuItem>
      <MenuItem onClick={handleDeleteRoles}>
        {t('controlUsers.actions.deleteRoles')}
      </MenuItem>
    </Menu>
  );
};

export default UserRowActionMenu;
