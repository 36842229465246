import { FC } from 'react';
import styled from 'styled-components';

import { TextFieldProps } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { TextField } from '@/components/ui/TextField';

interface SearchBarButtonProps {
  label: string;
  disabled: boolean;
  hideButton?: boolean;
  hideSearchIcon?: boolean;
  onSearch: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

// TODO: create an input component
const useStyles = makeStyles(() =>
  createStyles({
    input: {
      height: 56,
    },
  })
);

//#region  styled-component
const UISearchIcon = styled(SearchIcon)`
  cursor: pointer;
`;

const StyledSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  padding: 34px 0 0;
`;

const StyledSearchBar = styled(TextField)`
  width: 305px;

  fieldset {
    border-radius: 4px;
  }
`;
//#endregion

type SearchBarProps = Omit<TextFieldProps, 'variant'> &
  SearchBarButtonProps & {
    loading: boolean;
  } & React.ComponentPropsWithRef<'div'>;

export const SearchBarLight: FC<SearchBarProps> = ({
  placeholder,
  value,
  onChange,
  error,
  helperText,
  label,
  disabled,
  hideButton,
  onSearch,
  loading,
  hideSearchIcon = false,
  ...props
}) => {
  const classes = useStyles();

  return (
    <StyledSearchContainer {...props}>
      <StyledSearchBar
        placeholder={placeholder}
        label={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onSearch}
        disabled={disabled}
        variant="outlined"
        InputProps={{
          className: classes.input,
          startAdornment: props.InputProps?.startAdornment,
          endAdornment: !hideSearchIcon && (
            <UISearchIcon onClick={onSearch} color="primary" />
          ),
        }}
        inputProps={{
          maxLength: 13,
          minLength: 5,
          ...props.inputProps,
        }}
        required
        error={error}
        helperText={helperText}
      />
    </StyledSearchContainer>
  );
};

export default SearchBarLight;
