import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { theme } from '@/theme';

import styled, { css } from 'styled-components';

export const GridStyles = css`
  width: calc(100% - 16px);
  display: grid;
  align-items: center;
  gap: 8px;

  grid-template-columns: 10% 15% 75%;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 10% 15% 75%;
  }
`;

export const StyledGrid = styled(UIBox)`
  width: 100%;
  flex-direction: column;
  margin-bottom: 120px;

  & > * {
    padding: 0;
  }
`;

export const StyledSimplifiedGridHeader = styled(UIBox)`
  width: 100%;
  border-bottom: 1px solid #4b4b4b;
  ${GridStyles}
`;

export const StyledRowWrapper = styled(UIBox)`
  flex-direction: column;

  &:not(:nth-of-type(odd)) {
    background: #fbfbfb;
  }
`;

export const StyledGridRow = styled(UIBox)`
  width: 100%;
`;

export const StyledGridRowContent = styled(UIBox)`
  width: 100%;
  padding: 8px 0;

  ${GridStyles}
  & > * {
    &:last-child {
      width: 100%;
      justify-self: flex-end;
      justify-content: start;
    }
  }
`;

export const StyledSpan = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledUserId = styled(UIBox)`
  gap: 8px;
  align-items: center;
`;
