import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import { UIButtonWithIcon } from '@/components/ui/Button';

export const StyledFilterWrapper = styled(UIBox)`
  width: 100%;
  display: grid;
  //grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  padding: 24px;
  margin-bottom: 16px;

  & > * {
    margin: 0;
    padding: 0 !important;
    align-self: start;
  }
`;

export const StyledUpdateUserWrapper = styled.div`
  cursor: pointer;
  width: fit-content !important;
  align-self: center;
  align-items: center;
  display: flex;
  margin-top: 25px;
`;

export const StyledButtons = styled(UIBox)`
  width: fit-content !important;
  gap: 16px;
  display: flex;
  align-items: center;

  button {
    margin-top: 0;
  }
  & > * {
    margin: 0;
  }
`;

export const StyledButtonWithIcon = styled(UIButtonWithIcon)`
  width: fit-content;
  align-self: center;
  margin-top: 16px;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-self: center;
  justify-self: start;
  justify-content: space-between;
  width: 100%;
`;
export const StyledFileButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-self: center;
  justify-content: flex-end;
  width: 100%;
`;

export const StyledNoResults = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-weight: 800;
  }
`;
