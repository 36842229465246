import React, { useState } from 'react';
import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { UIAccordion } from '@/components/ui/Accordion';
import { UIAction } from '@/components/ui/Action';
import { UICheckbox } from '@/components/ui/Checkbox';
import { Typography } from '@/components/ui/Typography';
import { useRoleModalContext } from '@/context/roleModalContext';
import { fetchUsers } from '@/features/profiling/usersSlice';
import { useSelector } from '@/hooks/useSelector';
import UserRowActionMenu from '@/pages/ControlPanel/PageControlUsers/components/UserRowActionMenu';
import { UrlUpdateStore } from '@/pages/ControlPanel/PageStoreDetails/types';
import { isAdmin, isUsersManager } from '@/utils/user';
import { MoreVert } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useUpdateEffect } from 'react-use';
import {
  StyledAccordionItem,
  StyledActionsWrapper,
  StyledActionWrapper,
  StyledGridRow,
  StyledGridRowContent,
  StyledRole,
  StyledRowWrapper,
  StyledSpan,
  StyledStatus,
  StyledStatusWrapper,
  StyledUserId,
} from './style';
import { ProfilingUsersRowProps, TUserSelect } from './types';
import { User, UsersService } from '@/api/users';

const ProfilingUsersRow: React.FC<User & ProfilingUsersRowProps> = ({
  userId,
  name,
  storeCode,
  surname,
  roles,
  salesOrganization,
  streetAndHouseNumber,
  userStatus,
  setUserId,
  setAttentionModalVisibility,
  $showPartial,
  jobTitleName,
  exportUserSelected,
  checked,
  isCheckboxVisible,
  selectedUser,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const isUserPage =  history.location.pathname.split("/").find((pathEl: string) => pathEl === "manage-users");
  const user = useSelector(state => state.user);
  const { store } = useParams<UrlUpdateStore>();

  const [isAccordionExpanded, toggleAccordion] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<TUserSelect | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { setRoleModalState } = useRoleModalContext();

  useUpdateEffect(() => {
    if (userSelected) {
      exportUserSelected?.(userSelected);
    }
  }, [userSelected, userSelected?.checked]);

  const onRemoveClick = (userId: string): void => {
    setUserId(userId);
    setAttentionModalVisibility(true);
  };

  const onEditClick = (userId: string): void => {
    history.push(`${AppRoutes.MANAGE_USERS_EDIT}?userId=${userId}`, {
      fromStore: $showPartial,
      storeToRedirect: store,
    });
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUserSelected({ user: userId!, checked: event.target.checked });
  };

  const { roles: generalRoles } = useSelector(
    ({ profiling }) => profiling?.rolesManagement
  );

  const externalFnAdd = async (
    roles: string[],
    type: string
  ): Promise<void> => {
    try {
      switch (type) {
        case 'ADD': {
          const requestBody = { users: [selectedUser], roles };
          await UsersService.addUsersRolesEncoded({
            requestBody: { encodedRequest: btoa(JSON.stringify(requestBody)) },
          });
          break;
        }

        case 'DELETE': {
          const requestBody = { users: [String(userId)], roles };
          await UsersService.removeUsersRolesEncoded({
            requestBody: { encodedRequest: btoa(JSON.stringify(requestBody)) },
          });
          break;
        }
      }

      await dispatch(fetchUsers({}));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <StyledRowWrapper>
      <StyledGridRow key={userId}>
        <StyledGridRowContent
          $showPartial={$showPartial}
          $isUserAdmin={isAdmin(user)}
        >
          {isAdmin(user)
            && isUserPage
            && (
            <StyledUserId>
              <UICheckbox
                color="primary"
                onChange={(event): void => changeHandler(event)}
                checked={checked}
              />
            </StyledUserId>
          )}
          <StyledUserId>
            {!isAdmin(user) && isCheckboxVisible
              && isUserPage
              && (
              <UICheckbox
                color="primary"
                onChange={(event): void => changeHandler(event)}
                checked={checked}
              />
            )}
            {roles!.length > 1 && (
              <UIAccordion
                expanded={isAccordionExpanded}
                onClick={(): void => toggleAccordion(prevState => !prevState)}
              />
            )}
            <StyledSpan font="medium">{userId}</StyledSpan>
          </StyledUserId>
          <StyledSpan font="medium">
            {name} {surname}
          </StyledSpan>
          <Typography font="medium">
            {roles?.[0]}{' '}
            {roles!.length > 1 && (
              <StyledRole>+ ({roles!.length - 1})</StyledRole>
            )}
          </Typography>
          {!$showPartial ? (
            <>
              <StyledSpan font="medium">{`${salesOrganization?.join(
                ', '
              )}`}</StyledSpan>
              <StyledSpan font="medium">
                {storeCode} - {streetAndHouseNumber}
              </StyledSpan>
            </>
          ) : (
            <StyledSpan font="medium">
              {jobTitleName ? t(`${jobTitleName}`) : ''}
            </StyledSpan>
          )}
          <StyledStatusWrapper>
            <StyledStatus status={userStatus} />
            <StyledSpan font="medium">
              {t(`${userStatus?.toLowerCase()}`)}
            </StyledSpan>
          </StyledStatusWrapper>
          {!isAdmin(user) && isUsersManager(user) ? (
            <>
              <StyledActionWrapper
                onClick={(event): void => setAnchorEl(event.currentTarget)}
                id={'action-menu'}
              >
                <MoreVert />
              </StyledActionWrapper>
              <UserRowActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                onEditUser={(): void => onEditClick(userId!)}
                onDeleteUser={(): void => onRemoveClick(userId!)}
                onAddRoles={(): void =>
                  setRoleModalState({
                    open: true,
                    type: 'ADD',
                    roles: generalRoles,
                    externalSecondaryFn: (roles: string[], type: string) =>
                      externalFnAdd(roles, type),
                    externalTitle: t('controlUsers.multipleRole.admin.title'),
                  })
                }
                onDeleteRoles={(): void =>
                  setRoleModalState({
                    open: true,
                    type: 'DELETE',
                    roles: generalRoles,
                    externalSecondaryFn: (roles: string[], type: string) =>
                      externalFnAdd(roles, type),
                    externalTitle: t('controlUsers.multipleRole.admin.title'),
                  })
                }
              />
            </>
          ) : (
            isAdmin(user) && (
              <StyledActionsWrapper>
                {!$showPartial && (
                  <UIAction
                    icon="delete"
                    label=""
                    disabled={
                      roles?.includes('Admin') && !user.roles?.includes('Admin')
                    }
                    onClick={(): void => onRemoveClick(userId!)}
                  />
                )}
                <UIAction
                  icon="edit"
                  label={$showPartial ? t('edit') : ''}
                  disabled={
                    roles?.includes('Admin') && !user.roles?.includes('Admin')
                  }
                  onClick={(): void => onEditClick(userId!)}
                />
              </StyledActionsWrapper>
            )
          )}
        </StyledGridRowContent>
      </StyledGridRow>
      {isAccordionExpanded && (
        <StyledAccordionItem $isUserAdmin={isAdmin(user)} $isUserPage={isUserPage} $showPartial={$showPartial}>
          {roles?.slice(1).map(role => (
            <Typography key={role} font="medium">
              {role}
            </Typography>
          ))}
        </StyledAccordionItem>
      )}
    </StyledRowWrapper>
  );
};

export default ProfilingUsersRow;
